<template lang="pug">
  .tab-main-container
    .headline 登攀記録
    //-1段目
    .records-row
      //.records-row-inner
        .inner-half
          .title 都道府県
          .detail
            .count {{prefecturesCount}}
            .ranking 
              span 5/100位
        .inner-half
          .title 岩場
          .detail
            .count {{rockyCount}}
            .ranking 
              span 5/100位
      //-2段目
      #search-results(v-if="!recordAuth") 非公開
      .records-row-inner(v-else)
        .inner-half
          .title 総ルート
          .detail
            .count {{climbAllRouteCount}}
            .ranking 
              span {{ranking.climbAllRouteRank  > 0 && climbAllRouteCount > 0  ? ranking.climbAllRouteRank : ' - ' }}位
        .inner-half
          .title 完登ルート
          .detail
            .count {{climbCompleteRouteCount}}
            .ranking 
              span {{ranking.climbCompleteRouteRank  > 0 && climbCompleteRouteCount > 0  ? ranking.climbCompleteRouteRank : ' - ' }}位
              
    //-3段目
    .headline チェックイン記録
    #search-results(v-if="!checkinAuth") 非公開
    .records-row(v-else)
      .records-row-inner
        .inner-half
          .title 都道府県
          .detail
            .count {{prefecturesCount}}
            .ranking 
              span {{ranking.checkinPrefecturesRank  > 0 && prefecturesCount > 0  ? ranking.checkinPrefecturesRank : ' - ' }}位
        .inner-half
          .title 岩場
          .detail
            .count {{checkinRockyCnt}}
            .ranking 
              span {{ranking.checkinRockyRank  > 0 && checkinRockyCnt > 0  ? ranking.checkinRockyRank : ' - ' }}位
        .inner-half
          .title 岩
          .detail
            .count {{checkinRockCnt}}
            .ranking 
              span {{ranking.checkinRockRank  > 0 && checkinRockCnt > 0  ? ranking.checkinRockRank : ' - ' }}位
      .div(v-if="checkinAuth")
        Prefectures

</template>

<script>
import Prefectures from '@/components/myPage/prefectures.vue'

export default {
  // データオブジェクト
  data() {
    return {
      //登攀件数
      climbAllRouteCount : this.$parent.climbingAndCheckinCount.climbAllRouteCount,
      climbCompleteRouteCount : this.$parent.climbingAndCheckinCount.climbCompleteRouteCount,
      //チェックイン件数
      prefecturesCount : this.$parent.climbingAndCheckinCount.prefecturesCount,
      checkinRockyCnt : this.$parent.climbingAndCheckinCount.checkinRockyCnt,
      checkinRockCnt : this.$parent.climbingAndCheckinCount.checkinRockCnt,
      rockyRnk:0,
      //ランキング
      ranking:this.$parent.ranking,
      //都道府県リスト(prefectures.vueから参照可能にするための定義)
      prefecturesList:this.$parent.prefecturesList,
      //記録、チェックイン情報の参照権限
      recordAuth:Boolean(this.$parent.recordAuth),
      checkinAuth:Boolean(this.$parent.checkinAuth),
    }
  },
  // インスタンス作成後
  created() {
  },
  watch: {
    '$parent.climbingAndCheckinCount' (to, from) {
      this.climbAllRouteCount = this.$parent.climbingAndCheckinCount.climbAllRouteCount
      this.climbCompleteRouteCount = this.$parent.climbingAndCheckinCount.climbCompleteRouteCount
      //チェックイン件数
      this.prefecturesCount = this.$parent.climbingAndCheckinCount.prefecturesCount
      this.checkinRockyCnt = this.$parent.climbingAndCheckinCount.checkinRockyCnt
      this.checkinRockCnt = this.$parent.climbingAndCheckinCount.checkinRockCnt 
    },
    '$parent.prefecturesList' (to, from) {
      this.prefecturesList = this.$parent.prefecturesList
    }
  },
  // メソッド
  methods: {
  },
  // コンポーネント
  components: {
    Prefectures
  }
}

</script>

<style lang="stylus" scoped>
  #search-results
    margin 1rem
  .hidden
    background #cfd3d7
    color #606e79
    padding 1rem 2rem
  .records-row
    .records-row-inner
      display flex
      .inner-half
        background #FFF
        width 50%
        border 1px solid #bfc5c9
        .title
          font-size 0.8rem
          padding 0 0.5rem
          color #374047
        .detail
          display flex
          justify-content center
          align-items center
          .count
            font-size 1.3rem
            font-weight 600 
            color #006fbe
          .ranking
            font-size 0.8rem
            span 
              margin-left 1rem
              padding 0 1rem
              background #dee1e3
              color #606e79
              border-radius 5px 
</style>